import React from "react";

const Services = ({ classicHeader, darkTheme, src, width, height }) => {
  return (
    <section
      id="book"
      className={"section"}
      style={{ backgroundColor: "#292929" }} // Set the background color directly
    >
      <div style={{ position: "relative", width, height }}>
        <iframe
          src={src}
          scrolling="yes"
          title="Booking"
          frameBorder="0"
          width={width}
          height={height}
        />
      </div>
    </section>
  );
};

export default Services;
