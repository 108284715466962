import React from "react";
import Videobg from "../videos/home3.mp4";

const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  const handleScrollToBook = (e) => {
    e.preventDefault();
    const bookSection = document.querySelector("#book");
    if (bookSection) {
      bookSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section id="home">
      <div className="hero-wrap">
        {/* Re-added the overlay */}
        <div className="hero-mask opacity-5 bg-dark" />
        <div className="player hero-bg parallax">
          <video
            src={Videobg}
            autoPlay
            muted
            loop
            playsInline
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          ></video>
        </div>
        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="text-6 fw-500 text-white mb-2 mb-md-3">
                  Mister Flo Shop
                </h1>
                <p className="text-4 text-light mb-4">
                  <p
                    className={"text-2 mb-1 " + (darkTheme ? "text-light" : "")}
                  >
                    Located inside Salon Lofts @ Loft #15
                    <br />
                  </p>
                  <p
                    className={"text-2 mb-4 " + (darkTheme ? "text-light" : "")}
                  >
                    <a href="http://maps.google.com/?q=5840 Roswell Rd, Atlanta, GA 30328">
                      5840 Roswell Rd <br />
                      Atlanta, GA 30328
                    </a>
                  </p>
                  <p
                    className={"text-2 mb-1 " + (darkTheme ? "text-light" : "")}
                  >
                    <span className="text-primary text-3 me-2">
                      <i className="fas fa-phone" />
                    </span>
                    <a href="tel: +1 404-740-5652">404-740-5652</a>
                    <br />
                    <br />
                    <ul
                      className={
                        "social-icons justify-content-center justify-content-md " +
                        (darkTheme ? "social-icons-muted" : "")
                      }
                    >
                      <li className="social-icons-instagram">
                        <a
                          href="http://www.instagram.com/fredyfloatl"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-instagram fa-xl" />
                        </a>
                      </li>
                      <li className="social-icons-tiktok">
                        <a
                          href="https://www.tiktok.com/@fredyfloatl"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa-brands fa-tiktok fa-xl" />
                        </a>
                      </li>
                    </ul>
                  </p>
                </p>
                <a
                  className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                  href="#book"
                  onClick={handleScrollToBook}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
